import { faCircle, faCircleCheck, faLock, faLockOpen, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function MonthCard({openMonth, ...props}) {

    const { t } = useTranslation();

    return(
        <div className={props.inProgress ? 'month-content orangeBorder' : props.isDone ? 'month-content greenBorder' : 'month-content'} onClick={() => openMonth(props.month)}>
            {props.isDone || props.inProgress ? 
            <FontAwesomeIcon icon={faLockOpen} className="month-lock-icon"/>
            :
            <FontAwesomeIcon icon={faLock} className="month-lock-icon"/>      
            }
            <div className={props.inProgress ? 'monthCard-header inprogress' : 'monthCard-header'}>
                <p className={props.inProgress ? 'month-header inprogress' : 'month-header'}>{t('MONTHCARD.MONTH')} {props.month}</p>
                {props.inProgress ?
                <FontAwesomeIcon icon={faSpinner} className="progress-icon inprogress"/>
                : props.isDone ?
                <FontAwesomeIcon icon={faCircleCheck} className="progress-icon"/>
                :
                <FontAwesomeIcon icon={faCircle} className="progress-icon"/>
                }
            </div>
            <hr/>
            <p className={props.inProgress ? 'month-subtext inprogress' : 'month-subtext'}>{props.subtext}</p>
        </div>
    )
}