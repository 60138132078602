import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';  // Fügt Unterstützung für HTTP hinzu
import LanguageDetector from 'i18next-browser-languagedetector';  // Erkennt Browsersprache

i18n
  .use(Backend)  // Lädt Übersetzungsdateien von Backend
  .use(LanguageDetector)  // Erkennt automatisch die Sprache
  .use(initReactI18next)  // Bindet react-i18next ein
  .init({
    fallbackLng: 'en',
    lng: 'de',  // Standard-Sprache
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json'  // Pfad zu den Übersetzungen
    }
  });

export default i18n;