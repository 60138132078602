import '../styles/SignUp.css';
import { useState } from 'react';
import { useTranslation } from "react-i18next";

export default function SignUp({onClose}) {

    const { t } = useTranslation();

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const [isAccepted, setIsAccepted] = useState(false);

    const handleCheckboxChange = (event) => {
      setIsAccepted(event.target.checked); // Zustand aktualisieren
      if (event.target.checked) {
        console.log(isAccepted);
      } else {
        console.log(isAccepted);
      }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
          const response = await fetch("https://competefit.munich-software-studios.de/signup", {
            method: "POST",
            headers: {},
            body: JSON.stringify({
              username,
              email,
              password,
            }),
          });
    
          const data = await response.json();
          if (data.success) {
            setMessage("Registrierung erfolgreich!");
            window.location.href = "/welcome";
          } else {
            setMessage(t('MESSAGE.ERROR.REGISTRATION'));
          }
        } catch (error) {
          setMessage("Ein Fehler ist aufgetreten.");
        }
      };

    return(
        <div className="modal-overlay" onClick={onClose}>
            <div className="signup-content">

            <form onSubmit={handleSubmit}>
              <div>
                <p className='headline-standard'>{t('SIGNUP.HEADER')}</p>
                <hr className='signup-line'/>
                <p className='signup-subtext'>{t('SIGNUP.SUBTEXT')}</p>
                <input
                  className="input-signup"
                  type="text"
                  placeholder={t('PLACEHOLDER.USERNAME')}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div>
                <input
                  className="input-signup"
                  type="email"
                  placeholder={t('PLACEHOLDER.EMAIL')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div>
                <input
                  className="input-signup"
                  type="password"
                  placeholder={t('PLACEHOLDER.PASSWORD')}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div>
                <label className="standard-checkbox">
                  <input type="checkbox" checked={isAccepted} onChange={handleCheckboxChange} required/>
                  <span className="checkmark"></span>
                  <span className='label'>{t('SIGNUP.CONDITIONTEXT')}</span>
                </label>
              </div>
              <button className='btn-signup' type="submit" disabled={!isAccepted}>{t('BUTTON.SIGNUP')}</button>
            </form>
            {message && <p className='errorMeesage'>{message}</p>}
          </div>
        </div>
    )
}