import OptionCard from "./OptionCard";
import { useTranslation } from "react-i18next";
import styles from "../styles/ProgrammOptions.css"

export default function ProgrammOptions({onClose, onButtonClick}) {

    const { t } = useTranslation();

    const freeContent = [t('PROGRAMMOPTION.OPTIONCARD1.CONTENT.ONE'), t('PROGRAMMOPTION.OPTIONCARD1.CONTENT.TWO'), t('PROGRAMMOPTION.OPTIONCARD1.CONTENT.THREE')];
    const competefitContent = [t('PROGRAMMOPTION.OPTIONCARD2.CONTENT.ONE'), t('PROGRAMMOPTION.OPTIONCARD2.CONTENT.TWO'), t('PROGRAMMOPTION.OPTIONCARD2.CONTENT.THREE')];
    const coachingContent = [t('PROGRAMMOPTION.OPTIONCARD3.CONTENT.ONE'), t('PROGRAMMOPTION.OPTIONCARD3.CONTENT.TWO'), t('PROGRAMMOPTION.OPTIONCARD3.CONTENT.THREE')];

    return(
        <div className="modal-overlay" onClick={onClose}>
            <div className="option-content">
                <OptionCard programm={t('PROGRAMMOPTION.OPTIONCARD1.HEADER')} price={t('PROGRAMMOPTION.OPTIONCARD1.PRICE')} buttonText={t('BUTTON.LETSTRY')} content={freeContent} subText={t('PROGRAMMOPTION.OPTIONCARD1.SUBTEXT')} handleChoice={() => onButtonClick(t('PROGRAMMOPTION.OPTIONCARD1.HEADER'))}></OptionCard>
                <OptionCard programm={t('PROGRAMMOPTION.OPTIONCARD2.HEADER')} price={t('PROGRAMMOPTION.OPTIONCARD2.PRICE') + '/MO'} buttonText={t('BUTTON.STARTPROGRAMM')} content={competefitContent} subText={t('PROGRAMMOPTION.OPTIONCARD2.SUBTEXT')} handleChoice={() => onButtonClick(t('PROGRAMMOPTION.OPTIONCARD2.HEADER'))} isHighlighted></OptionCard>
                <OptionCard programm={t('PROGRAMMOPTION.OPTIONCARD3.HEADER')} price={t('PROGRAMMOPTION.OPTIONCARD3.PRICE') + '/MO'} buttonText={t('BUTTON.CONTACT')} content={coachingContent} subText={t('PROGRAMMOPTION.OPTIONCARD3.SUBTEXT')} handleChoice={() => onButtonClick(t('PROGRAMMOPTION.OPTIONCARD3.HEADER'))}></OptionCard>
            </div>
        </div>
    )

}