import './App.css';
import LandingPage, {action as logiinAction} from './pages/Landingpage';
import StartPage from './pages/StartPage';
import DropdownMenu from './components/DropdownMenu';
import ContactForm from './components/ContactForm';
import OverviewPage from './pages/Overview';
import MonthOverview from './pages/MonthContent/MonthOverview'
import Documents from './pages/MonthContent/Documents'
import Evaluation from './pages/MonthContent/Evaluation'
import Workspace from './pages/MonthContent/Workspace'

import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie'; // Importiere js-cookie
import { DataProvider } from './components/DataProvider';

function App() {

  const { t, i18n } = useTranslation();

  const [isLoggedIn, setIsLoggedIn] = useState(false); //Cookie abfragen, ob vorhanden
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);

  const items = [t('DROPDOWN.ITEM.FAQ'), t('DROPDOWN.ITEM.PRICING'), t('DROPDOWN.ITEM.ABOUTME')];
  const profilItems = [t('DROPDOWN.PROFIL.ITEM.PROFIL'), t('DROPDOWN.PROFIL.ITEM.VIDEOS'), t('DROPDOWN.PROFIL.ITEM.HELP'), t('DROPDOWN.PROFIL.ITEM.LOGOUT')];

  useEffect(() => {
    const authToken = Cookies.get('authToken');

    if(authToken) {
      setIsLoggedIn(true);
    }

  }, [])

  const router = createBrowserRouter([
    {path:'/', 
      element: <LandingPage/>, 
      action: logiinAction},
    {path:'/welcome', 
      element: <StartPage/>},
    {path:'/overview', 
      element: <OverviewPage/>},
    {path:'/month/:month', 
      element: <MonthOverview/>,
      children: [
        {path: "documents",
          element: <Documents/>
        },
        {path: "evaluation",
          element: <Evaluation/>
        },
        {path: "workspace",
          element: <Workspace/>
        }
      ]}
  ])

  function handleSelection(subject){
    setIsContactFormOpen(true);
    console.log(subject);
  };

  const handleCloseConctactForm = () => {
    setIsContactFormOpen(true);
  };

  // Funktion zum Ändern der Sprache
  function changeLanguage(language){
    i18n.changeLanguage(language);
  };
  
  return (
    <div className="App">
      <DataProvider>
        <header className="App-header">
          {!isLoggedIn ?
          <DropdownMenu icon={faChevronDown} title={t('DROPDOWN.TITLE.DEFAULT')} items={items} handleSelection={handleSelection}/>
          :
          <DropdownMenu icon={faChevronDown} title={'Username'} items={profilItems} handleSelection={handleSelection}/>
          }
          <select className="langChange" onChange={(e) => changeLanguage(e.target.value)}>
            <option value="de">{t('LANGUAGE.DE')}</option>
            <option value="en">{t('LANGUAGE.EN')}</option>
          </select>
          <RouterProvider router={router}/>
          {isContactFormOpen ? 
            <ContactForm onClose={handleCloseConctactForm}/>
            : <></>
          }
        </header>
      </DataProvider>
    </div>
  );
}

export default App;
