import { useTranslation } from "react-i18next";

export default function OptionCard({content, handleChoice, ...props}) {

  const { t } = useTranslation();

    return(
        <div className={props.isHighlighted ? "option-box highlighted" : "option-box"}>
            {props.isHighlighted ? 
            <div className="option-popular">{t('PROGRAMMOPTION.POPULAR')}</div> : <></>}
            <h5>{props.programm}</h5>
            <h1 className="option-price">{props.price}</h1>
            <hr className="option-line"/>
            <ul className="option-list">
              {content.map((arg, index) => (
                <li key={index}>{arg}</li>
              ))}
            </ul>
            <button className="btn-standard btn-primary" onClick={handleChoice}>{props.buttonText}</button>
            <p className="option-subtext">{props.subText}</p>
        </div>
    )
}