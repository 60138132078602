// src/DataContext.js
import React, { createContext, useState, useEffect } from 'react';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Prüfen, ob Daten im Local Storage vorhanden sind
    const storedData = localStorage.getItem('fetchedData');
    if (storedData) {
        setUserData(JSON.parse(storedData)); // Gespeicherte Daten setzen, falls vorhanden
    } else {
      fetchData(); // Daten fetchen, falls sie nicht vorhanden sind
    }
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('https://competefit.munich-software-studios.de/user');
      const result = await response.json();
      console.log(result);
      setUserData(result);
      localStorage.setItem('fetchedData', JSON.stringify(result)); // Daten speichern
    } catch (error) {
      console.error('Fehler beim Fetch:', error);
    }
  };

  return (
    <DataContext.Provider value={userData}>
      {children}
    </DataContext.Provider>
  );
};
