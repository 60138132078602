import LoginField from "../components/LogInField";
import SignUp from "../components/SignUp";
import '../styles/Landingpage.css';
import { json, redirect } from "react-router-dom";
import { useState } from "react";
import ProgrammOptions from "../components/ProgrammOptions";

import { useTranslation } from "react-i18next";

export default function LandingPage() {

    const { t } = useTranslation();

    const [isSignUpOpen, setIsSignUpOpen] = useState(false);
    const [isOptionsOpen, setIsOptionOpen] = useState(false);


    const handleOpenSignUp = () => {
        setIsSignUpOpen(true);
    };

    const handleOpenOptions = () => {
        setIsOptionOpen(true);
    };

    const handleCloseSignUp = (e) => {
        if (e.target.classList.contains("modal-overlay")) {
            setIsSignUpOpen(false);
        }
    };

    const handleCloseOptions = (e) => {
        if (e.target.classList.contains("modal-overlay")) {
            setIsOptionOpen(false);
        }
    };

    const handleCloseOptionsByButton = (choice) => {
        console.log(choice)
        if(choice.includes("Free Trail") || choice.includes("CompeteFit")){
            console.log(choice);
            setIsOptionOpen(false);
            setIsSignUpOpen(true);
        }
        else if(choice.includes("Personaltraining")) {
            console.log(choice);
            setIsOptionOpen(false);
        } else{
            console.log('No Programm')
        }
    };

    return(
        <div className="container">
            <div className="box">
                <h1 className="App-name">Compete<span className="App-name-fit">Fit</span></h1>
                <p className="subtext">{t('LANDINGPAGE.SUBHEADER')}</p>
                <hr className="LP-line"/>
                <p className="description">
                    {t('LANDINGPAGE.DESCRIPTION')}
                </p>
                <hr className="LP-line"/>
            </div>
            <div className="boxLogIn">
                <LoginField openSignUp={handleOpenSignUp}/>
                <p className="loginNote" onClick={handleOpenOptions}>{t('LOGIN.NOTE')}</p>
            </div>  
            {isSignUpOpen ? 
             <SignUp onClose={handleCloseSignUp}/>
             : <></>
            }
            <></>
            {isOptionsOpen ? 
             <ProgrammOptions onClose={handleCloseOptions} onButtonClick={handleCloseOptionsByButton}/>
             : <></>
            }
        </div>
    )
}

export async function action({request}) {

    const data = await request.formData();
    const authData = {
        username: data.get('username'),
        password: data.get('password'),
    }

    const response = await fetch('https://competefit.munich-software-studios.de/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(authData)
    })

    if(response.status === 422 || response.status === 401) {
        return response;
    }

    if(!response.ok) {
        throw json({message:'Could not authenticate user'}, {status: 500});
    }

    return redirect('/welcome');
}